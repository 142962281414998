import Layout from "@/layout/profile";

const adminRouter = {
  path: "/admin",
  name: "Admin",
  redirect: "/admin/project",
  component: Layout,
  children: [
    {
      path: "project",
      name: "AdminProjectPage",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/Admin/AdminProject.vue"
        ),
      meta: {
        admin: true,
      },
    },
    {
      path: "level",
      name: "AdminLevelPage",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/Admin/AdminLevel.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "checkTask",
      name: "checkTask",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/Admin/CheckTask.vue"),
      meta: {
        admin: true,
      },
    },
    // {
    //   path: "invite",
    //   name: "GOSinvite",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "about" */ "@/views/Admin/AdminInviteLinks.vue"
    //     ),
    //   meta: {
    //     admin: true,
    //   },
    // },
    {
      path: "projects/manage-jobs",
      name: "ProjectJobs",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/Admin/AdminJobs"),
      meta: {
        admin: true,
      },
    },
    {
      path: "organization/projects",
      name: "GOSprojects",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/Admin/AdminOrganizationProjects.vue"
        ),
      meta: {
        admin: true,
      },
    },
    {
      path: "project/stages",
      name: "ProjectStages",
      component: () => import("@/views/Admin/AdminProjectStages.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "company-requests",
      name: "CompanyRequests",
      component: () => import("@/views/Admin/CompanyRequests.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "moderation/self-project",
      name: "SelfProjectModeration",
      component: () => import("@/views/Admin/AdminSelfProjectModeration.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "org-statuses",
      name: "AdminOrgStatuses",
      component: () => import("@/views/Admin/AdminOrgStatuses.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "test/add",
      name: "TestingAdmin",
      component: () => import("@/views/TestingUnit/TestingAdmin.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "test/:test_id/edit",
      name: "TestEditAdmin",
      component: () => import("@/views/TestingUnit/TestingAdmin.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "tests/manage",
      name: "ManageTestsAdmin",
      component: () => import("@/views/Admin/AdminManageTests.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "coins-and-achievements",
      name: "CoinsAndAchievementsChecker",
      component: () =>
        import("@/views/Admin/AdminCheckCoinsAndAchievements.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "competence-transfer",
      name: "CompetenceTransfer",
      component: () => import("@/views/Admin/AdminCompetenceTransfer.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "competence-archieve",
      name: "AdminCompetenceArchieve",
      component: () => import("@/views/Admin/AdminCompetenceArchieve.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "jobs/requests",
      name: "AdminJobsRequests",
      component: () => import("@/views/Admin/AdminJobsRequests.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "sprints/requests",
      name: "AdminSprintsCheckingStep1",
      component: () => import("@/views/Admin/Sprints/Step1.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "sprints/step/2",
      name: "AdminSprintsCheckingStep2",
      component: () => import("@/views/Admin/Sprints/Step2.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "sprints/step/3",
      name: "AdminSprintsCheckingStep3",
      component: () => import("@/views/Admin/Sprints/Step3.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "sprints/step/4",
      name: "AdminSprintsCheckingStep4",
      component: () => import("@/views/Admin/Sprints/Step4.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "products/manage",
      name: "AdminProductsManage",
      component: () => import("@/views/Admin/AdminProductsManage.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "products/orders/manage",
      name: "AdminOrdersManage",
      component: () => import("@/views/Admin/AdminOrders.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "news/manage",
      name: "AdminNewsManage",
      component: () => import("@/views/Admin/AdminNews.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: "faq/manage",
      name: "AdminFAQManage",
      component: () => import("@/views/Admin/AdminFAQ.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: `rating-students-list`,
      name: "AdminRatingList",
      component: () => import("@/views/Organization/getStudentsList.vue"),
      meta: {
        admin: true,
      },
    },
    {
      path: `mentors`,
      name: "MentorSelect",
      component: () => import("@/views/Admin/Calendar/SelectMentor.vue"), 
      meta: {
        admin: true,
      },
    },
    {
      path: `slots/mentor`,
      name: "MentorSlots",
      component: () => import("@/views/Admin/Calendar/SlotsView.vue"), 
      meta: {
        admin: true,
      },
    },
    {
      path: `events`,
      name: "EventSelect",
      component: () => import("@/views/Admin/Events/SelectEvent.vue"), 
      meta: {
        admin: true,
      },
    },
    {
      path: `event/users`,
      name: "EventUsers",
      component: () => import("@/views/Admin/Events/EventStudents.vue"), 
      meta: {
        admin: true,
      },
    },
  ],
};

export default adminRouter;
